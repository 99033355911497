import React from "react";
import { Link } from "gatsby";

import "./index.scss";

export default function Header() {
  return (
    <div>
      <title>Loving Lactation</title>
      <img
        src="/images/logo.png"
        alt="Loving Lactation LLC"
        className="mobile-logo"
      />
      <header className="site-header">
        <Link to="/#about">About</Link>
        <Link to="/#forms">Forms</Link>
        <img src="/images/logo.png" alt="Loving Lactation LLC" />
        <Link to="/#services">Services</Link>
        <Link to="/#contact">Contact</Link>
      </header>
    </div>
  );
}
