import React from "react";
import { Fade } from "react-awesome-reveal";

import "./index.scss";

export default function SectionImage({ src, alt }) {
  return (
    <Fade duration={2000}>
      <img alt={alt} src={`/images/${src}`} className="section-img" />
    </Fade>
  );
}

SectionImage.defaultProps = {
  src: "1.jpg",
  alt: "Section Image",
};
