import React from "react";
import { v4 as uuidv4 } from "uuid";

import Item from "./Item";
import { SectionImage } from "../";

import "./index.scss";

export default function Services() {
  return (
    <div id="services">
      <SectionImage src="4.jpg" />
      <h2>Services and Pricing</h2>
      <div className="services">
        {services.map((service) => (
          <Item
            key={uuidv4()}
            name={service.name}
            length={service.length}
            price={service.price}
            twinPrice={service.twin_price}
            description={service.description}
          />
        ))}
      </div>
      <h3>Payment</h3>
      <p>
        <b>
          Now accepting Blue Cross Blue Shield, Anthem, and Cigna Insurance.
        </b>
      </p>
      <p>
        Payment is due at the appointment. You may use your Flexible or Health
        Spending Account, debit/credit cards, check or cash. A superbill will be
        provided to submit to your insurance company for reimbursement.
        Insurance reimbursement is not guaranteed but most companies provide
        lactation services.
      </p>
      <h3>Cancellation Policy</h3>
      <p>
        If possible, please <a href="tel:770-833-0937">call</a>, text or email
        within 12 hours if you need to reschedule or cancel.
      </p>
      <p>
        Please note that due to Covid-19, masks are required during the consult
        I follow all current CDC guidelines for healthcare workers.
      </p>
    </div>
  );
}

const services = [
  {
    name: "Initial Office Lactation Consult",
    length: "90-120 min.",
    price: "$200",
    twin_price: "$250",
  },
  {
    name: "Initial Home Lactation Consult",
    length: "90-120 min.",
    price: "$225",
    twin_price: "$275",
    description:
      " During this visit, I will listen to and review your medical history and birth story. An assessment of your baby’s oral anatomy and your breasts will be done. I will observe a feeding to check positioning and latch, suck/swallow coordination and milk transfer. A pre and post feeding weight will be done and I will problem solve any concerns. You will receive and we will discuss a written feeding plan.",
  },
  {
    name: "Follow Up Office Lactation Consult",
    length: "60 min.",
    price: "$100",
    twin_price: "$150",
  },
  {
    name: "Back to Work Consult",
    length: "60 min.",
    price: "$100",
    description:
      "Are you returning to the workplace after being at home with your baby and unsure of how to balance work, breastfeeding, pumping and maintain your milk supply? We will discuss a working/feeding/pumping plan and how to be successful with it.",
  },
  {
    name: "Breastfeeding Support Group",
    length: "60 min. Weekly",
    price: "Free",
    description:
      "Whether you are expecting a baby or have already delivered, support   and encouragement is very important. Individual lactation consults will not be done at this time, but education and support will be offered to put you on the path to meet your unique breastfeeding goals.",
  },
];
