import * as React from "react";
import Page from "../layouts";

import {
  About,
  AppointmentPrep,
  Contact,
  InitialConsult,
  JansPlan,
  Services,
} from "../components";

const IndexPage = () => {
  return (
    <Page>
      <About />
      <InitialConsult />
      <JansPlan />
      <AppointmentPrep />
      <Services />
      <Contact />
    </Page>
  );
};

export default IndexPage;
