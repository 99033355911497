import React from "react";
import { v4 as uuidv4 } from "uuid";

import { SectionImage } from "../";

export default function JansPlan() {
  const items = [
    "Take a brief history of your pregnancy, labor and postpartum and what has occurred prior to the consult",
    "Observe a feeding and help to resolve any concerns such as sore nipples or difficult latch",
    "Weigh your baby before and after feedings to assess milk transfer",
    "Discuss and provide you with a written care plan",
    "Send a letter to your pediatrician",
    "Provide a superbill that you file with your insurance company for reimbursement",
    "Provide follow up care as needed",
  ];

  return (
    <div>
      <SectionImage src="3.jpg" />
      <h2>What I Will Do For You</h2>
      <ul>
        {items.map((item) => (
          <li key={uuidv4()}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
