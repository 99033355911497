import React from "react";

import "./index.scss";

export default function Contact() {
  return (
    <div id="contact">
      <h2>For Appointment Contact</h2>
      <div className="form-links">
        <a
          href="https://go.lactationnetwork.com/LovingLactationLLC"
          target="_blank"
          style={{ color: "#f2f2f2" }}
        >
          Request a consultation
        </a>
      </div>
      <p>
        Janet Mannion RN, BSN, IBCLC
        <br />
        Lactation Consultant
        <br />
        <AddLink text="500 Lanier Ave W Suite 703" />
        <br />
        <AddLink text="Fayetteville, GA 30214" />
        <br />
        <a href="tel:770-833-0937">770-833-0937</a>
        <br />
        janet[at]lactationga.com
      </p>
    </div>
  );
}

const AddLink = ({ text }) => (
  <a href={path} target="_blank">
    {text}
  </a>
);

const path = `https://www.google.com/maps/place/500+W+Lanier+Ave+%23703,+Fayetteville,+GA+30214/@33.4463666,-84.4663791,17z/data=!3m1!4b1!4m5!3m4!1s0x88f4ee4ba0f33c09:0xe1ba7f8dc7a472f!8m2!3d33.4463621!4d-84.4641904`;
