import React from "react";
import "./index.scss";

export default function Footer() {
  return (
    <footer className="site-footer">
      <p>&copy; {new Date().getFullYear()} Loving Lactation LLC</p>
    </footer>
  );
}
