import React from "react";
import { Footer, Header } from "../components";

export default function PageLayout({ children }) {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }

  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
