import React from "react";
import { FaRegFilePdf } from "react-icons/fa";

import "./index.scss";

export default function FormLinks() {
  return (
    <div className="form-links">
      <a
        href="/forms/consent.pdf"
        download="Lactation Consultation Consent Form.pdf"
      >
        <FaRegFilePdf /> Consent
      </a>
      <a
        href="/forms/intake-history.pdf"
        download="Lactation Intake History.pdf"
      >
        <FaRegFilePdf /> Intake History
      </a>
    </div>
  );
}
