import React from "react";
import { FaUserFriends, FaUser, FaClock } from "react-icons/fa";

export default function Item({ name, length, price, twinPrice, description }) {
  return (
    <div className="service-item">
      <h4>{name}</h4>
      <div className="tags">
        <Tag value={length} icon={<FaClock />} title="Length" />
        <Tag value={price} icon={<FaUser />} title="Price" />
        {twinPrice && (
          <Tag value={twinPrice} icon={<FaUserFriends />} title="Twin Price" />
        )}
      </div>
      {description && <p>{description}</p>}
    </div>
  );
}

Item.defaultProps = {
  name: "",
  length: "",
  price: "",
  twinPrice: "",
  description: "",
};

const Tag = ({ value, icon, title }) => (
  <span className="tag" title={title}>
    {icon} {value}
  </span>
);
