import React from "react";
import { FormLinks } from "../";

export default function AppointmentPrep() {
  return (
    <div id="forms">
      <h2>Preparing for your appointment</h2>
      <p style={{ marginBottom: "12px" }}>
        Forms required for your initial consult are:
      </p>
      <FormLinks />
      <p>(You may download or complete at time of consult)</p>
      <p>We will go over these during your consult.</p>
      <p>
        <b>
          Plan feedings prior to your appointment so your baby will be hungry,
          awake when we meet.
        </b>
      </p>
    </div>
  );
}
