import React from "react";
import { v4 as uuidv4 } from "uuid";

import { SectionImage } from "../";

export default function InitialConsult() {
  const items = [
    "Painful breastfeeding",
    "Low milk supply",
    "Oversupply or overactive letdown",
    "Baby has slow weight gain or weight loss",
    "Assess tongue and lip ties",
    "Nipple damage",
    "Pumping and flange sizing",
  ];

  return (
    <div>
      <SectionImage />
      <h2>
        An initial lactation consult may help you if your breastfeeding
        experience is not going as you expected and you need guidance and
        support. Lactation services may help you with:
      </h2>
      <ul>
        {items.map((item) => (
          <li key={uuidv4()}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
